import * as React from "react";
import Layout from "../components/common/layout";
import Button from '../components/common/button';

const PageNotFound = () => {
  return (
      <Layout>
          <section className="flex flex-col justify-center items-center gap-6 w-full px-8 min-h-[50vh] pt-20 pb-24 ">
            <h1 className="dx-h1 leading-[46px] text-center">ページが見つかりません</h1>
            <div className="text-center">
                <p className="dx-p2 leading-7">指定されたURLのページは存在しません。</p>
                <p className="dx-p2 leading-7">
                    サイト更新などによってURLが変更になったか、URLが正しく入力されていない可能性があります。
                </p>
            </div>
            <Button to="/"><span className="leading-6">ホームページに戻る</span></Button>
          </section>
    </Layout>
  );
};

export default PageNotFound;
